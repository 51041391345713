import React from 'react'
import { 
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Roubo from './Roubo';
import RouboFurto from './RouboColisao'

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Roubo />
      </Route>
      <Route exact path="/roubo">
        <Roubo />
      </Route>
      <Route exact path="/roubo-furto-perda-total">
        <RouboFurto />
      </Route>
    </Switch>
  </Router>
)

export default App;