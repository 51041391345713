import { useState } from "react";

function ZaiaWidget() {
  const [chatVisible, setChatVisible] = useState(false);

  const toggleChat = () => {
    setChatVisible(!chatVisible);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
        }}
      >
        <button
          onClick={toggleChat}
          style={{
            borderRadius: "50%",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer", 
            padding: "0", 
            outline: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="https://i.ibb.co/tqCH0nM/iara-f142d6848fd651db92e9881a8f512ffd.png"
            alt="Chat Logo"
            style={{
              width: "60px", 
              height: "60px",
              transition: "transform 0.3s ease",
            }} 
            onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.1)"} 
            onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"} 
          />
        </button>
      </div>

      {/* Iframe do chat da Zaia */}
      <div
        style={{
          position: "fixed",
          bottom: "90px", 
          right: "20px",
          width: "450px", 
          height: "500px", 
          backgroundColor: "#fff",
          borderRadius: "15px", 
          boxShadow: "0px 0px 15px rgba(0,0,0,0.3)", 
          zIndex: 1000,
          overflow: "hidden",
          transform: chatVisible ? "translateY(0)" : "translateY(20px)",
          opacity: chatVisible ? 1 : 0,
          transition: "transform 0.4s ease, opacity 0.4s ease",
          pointerEvents: chatVisible ? "all" : "none",
        }}
      >
        {chatVisible && (
          <iframe
            src="https://platform.zaia.app/embed/chat/24866"
            title="Zaia Chat"
            width="100%"
            height="100%"
            style={{ border: "none", borderRadius: "15px" }}
            allow="microphone; camera"
          >
          </iframe>
        )}
      </div>
    </>
  );
}

export default ZaiaWidget;
