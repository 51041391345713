import { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "./App.css";

import togarantidoLogo from "./togarantido.svg";
import motoIcon from "./moto.svg";

import suhaiLogo from "./suhai.svg";
import rappiBankLogo from "./assets/images/logo-rappi-bank.svg";
import videoPlaceholder from "./assets/images/video-thumbnail.png";

function Roubo() {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <div className="app">
      <header className="header">
        <div className="content">
          <img
            className="header__logo"
            src={rappiBankLogo}
            alt="Rappi Bank - TôGarantido"
          />
          <h1 className="header__title">
            Não deixe que imprevistos atrapalhem a sua rotina.
          </h1>
          <p className="header__text">
            100% online, econômico, imediato e sem papéis.
          </p>
          <a
            href="https://chatbot.togarantido.com.br/rappi-003"
            className="btn btn--primary"
          >
            Cotar meu seguro
          </a>
        </div>
      </header>
      <main>
        <section className="overview">
          <div className="content">
            <div className="video-wrapper">
              {showVideo && (
                <iframe
                  src="https://player.vimeo.com/video/616930723?h=85143402e3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  title="4818 to garantido.mp4"
                ></iframe>
              )}
              <img
                src={videoPlaceholder}
                className="video-placeholder"
                onClick={() => setShowVideo(true)}
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <p className="overview__text">
              O{" "}
              <strong>
                Rappi possui uma condição com desconto diferenciado
              </strong>{" "}
              para proteção da sua moto! Chega de dificuldades para fazer o
              seguro da sua moto. Aqui ela é bem-vinda, independente de marca,
              ano ou modelo.{" "}
              <strong>A Suhai é a maior seguradora de moto do Brasil</strong> e
              com preço que cabe no seu bolso! Confira a cobertura que
              oferecemos para a proteção de sua moto.
            </p>
            <a
              href="https://chatbot.togarantido.com.br/rappi-003"
              class="btn btn--primary"
              target="_blank" rel="noreferrer"
            >
              Cotar meu Seguro
            </a>
          </div>
        </section>
        <section className="section-coverage">
          <div class="content">
            <h2 className="section-coverage__title">
              Confira as coberturas para a proteção de sua moto
            </h2>
            <div class="coverage">
              <div class="coverage__title">
                <h4>Coberturas</h4>
                <img src={suhaiLogo} />
              </div>
              <div class="coverage__separator" />
              <table class="coverage__table">
                <tbody>
                  <tr>
                    <td>Roubo e furto</td>
                    <td>Cobertura FIPE*</td>
                  </tr>
                  <tr>
                    <td>Assistência 24 horas</td>
                    <td>Sim</td>
                  </tr>
                  <tr>
                    <td>Reboque</td>
                    <td>Até 200KM</td>
                  </tr>
                  <tr>
                    <td>Chaveiro</td>
                    <td>Até R$ 100,00</td>
                  </tr>
                  <tr>
                    <td>Troca de pneu</td>
                    <td>Até 3 acionamentos</td>
                  </tr>
                  <tr>
                    <td>Auxilio mecânico</td>
                    <td>Sim</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <a
                        href="https://chatbot.togarantido.com.br/rappi-003"
                        class="btn btn--primary"
                        target="_blank" rel="noreferrer"
                      >
                        Cotar meu Seguro
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Roubo e Furto</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Seu veículo foi roubado ou furtado? Você não fica no
                    prejuízo.
                    <br />
                    <br />A <strong>Suhai</strong> irá garantir que tudo fique
                    bem. Você recebe a indenização contratada na apólice se ele
                    não for encontrado em até 30 dias ou se ele for encontrado
                    mas com danos acima de 75%.
                    <br />
                    <br />
                    <a
                      href="https://suhaiseguradora.com/wp-content/uploads/Marco-atual.pdf"
                      target="_blank" rel="noreferrer"
                    >
                      Consulte informações detalhadas e limites nas Condições
                      Gerais
                    </a>
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Assistência 24 horas
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    A <strong>Suhai</strong> pensa na sua segurança e comodidade
                    a todo momento, com isso você pode contar com o Serviço de
                    Assistência 24 Horas para os eventos de{" "}
                    <strong>chaveiro</strong>, <strong>reboque</strong>,{" "}
                    <strong>transporte alternativo</strong>,{" "}
                    <strong>troca de pneus</strong>,{" "}
                    <strong>auxílio mecânico</strong> ou{" "}
                    <strong>serviço de taxi</strong>.<br />
                    <br />
                    Nossa Central 24 horas estará disponível todos os dias e 24
                    horas.
                  </p>
                  <p>
                    <a
                      href="https://suhaiseguradora.com/wp-content/uploads/servico-de-assistencia-24-horas-2016-12.pdf"
                      target="_blank" rel="noreferrer"
                    >
                      Condições gerais
                    </a>
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Emissão do Seguro e Início de Cobertura
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    A emissão do seguro pela Suhai é feita após o Pagamento do
                    Boleto da 1ª Parcela ou parcela única e a aprovação da
                    Autovistoria ou Instalação Rastreador.
                  </p>
                  <p>
                    O início da cobertura do seguro se dará a partir da
                    data/hora da efetivação da instalação do sistema de
                    rastreamento, ou quando a contratação for sem sistema de
                    rastreamento, o início da cobertura do seguro se dará a
                    partir da data/hora da aprovação da Vistoria Prévia pela
                    Seguradora e quando do pagamento do boleto da 1ª parcela ou
                    parcela única e no prazo previsto.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
            <a
              href="https://chatbot.togarantido.com.br/rappi-003"
              class="btn btn--primary"
              target="_blank" rel="noreferrer"
            >
              Cotar meu Seguro
            </a>
            <p className="coverage_info">
              *Cobertura FIPE para veículos até 180 mil (ou até 300 mil se
              contratar reduzindo a FIPE a 60%)
            </p>
          </div>
        </section>
      </main>
      <footer class="footer">
        <div class="content">
          <p class="footer__copyright">
            Feito por{" "}
            <a href="https://www.togarantido.com.br" target="_blank" rel="noreferrer">
              <img src={togarantidoLogo} />
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Roubo;
