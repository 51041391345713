import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './App.css';

import togarantidoLogo from './togarantido.svg';
import motoIcon from './moto.svg';
import headerImage from './header.jpg';
import suhaiLogo from './suhai.svg';

function RouboColisao() {
  return (
    <div className="app">
      <header className="header">
        <h1 class="header__title">Seguro Moto</h1>
      </header>
      <main>  
        <img src={headerImage} class="productimage" />
        <div class="content">
          <div class="product__icon">
            <img src={motoIcon} />
            Seguro
          </div>
          <div class="productname">
            <h2>Seguro Moto SUHAI</h2>
            <h3>Seguros para Roubo, Furto e Perda Total por Colisão e Outros Danos.</h3>
          </div>
          <div style={{
            padding: "75% 0 0 0",
            position: "relative"
          }}><iframe src="https://player.vimeo.com/video/616930723?h=85143402e3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{
            position:"absolute",
            top:0,
            left:0,
            width: "100%",
            height:"100%"
         }} title="4818 to garantido.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          <p>O <strong>Rappi Bank</strong> possui uma condição com desconto diferenciado para proteção da sua moto.</p>
          <div class="product__controls">
            <a href="https://chatbot.togarantido.com.br/rappi-003" class="btn btn--primary" target="_blank">Cotar meu Seguro</a>
          </div>
          <p>Chega de dificuldades para fazer o seguro da sua moto. Aqui ela é bem-vinda, independente de marca, ano ou modelo.</p>
          <p>A Suhai é a maior seguradora de moto do Brasil e com preço que cabe no seu bolso!</p>
          <p>Confira abaixo a cobertura que oferecemos para a proteção de sua moto:</p>
          <div class="coverage">
            <div class="coverage__title">
              <h4>Cobertura</h4>
              <img src={suhaiLogo} />
            </div>
            <div class="coverage__separator" />
            <table class="coverage__table">
              <tbody>
                <tr>
                  <td>Roubo e furto</td>
                  <td>Cobertura FIPE*</td>
                </tr>
                <tr>
                  <td>Perda Total</td>
                  <td>Cobertura FIPE*</td>
                </tr>
                <tr>
                  <td>Assistência 24 horas</td>
                  <td>Sim</td>
                </tr>
                <tr>
                  <td>Reboque</td>
                  <td>Até 200KM</td>
                </tr>
                <tr>
                  <td>Chaveiro</td>
                  <td>Até R$ 100,00</td>
                </tr>
                <tr>
                  <td>Troca de pneu</td>
                  <td>Até 3 acionamentos</td>
                </tr>
                <tr>
                  <td>Auxilio mecânico</td>
                  <td>Sim</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="product__controls">
            <a href="https://chatbot.togarantido.com.br/rappi-003" class="btn btn--primary" target="_blank">Cotar meu Seguro</a>
          </div>
          <Accordion allowZeroExpanded>
          <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Roubo e Furto
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                      Seu veículo foi roubado ou furtado? Você não fica no prejuízo.<br/><br />A <strong>Suhai</strong> irá garantir que tudo fique bem. Você recebe a indenização contratada na apólice se ele não for encontrado em até 30 dias ou se ele for encontrado mas com danos acima de 75%. 
                      <br /><br />Consulte informações detalhadas e limites nas Condições Gerais
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       Perda Total
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                      Amplie a sua proteção contratando a Cobertura de Perda Total, com ela, além da proteção de Roubo e Furto, a Suhai irá te indenizar caso ocorra algum dano ao seu veículo causado por Colisões, Incêndio, explosões acidentais, quedas ou outros acidentes de causa externa, incluindo alagamento, inundação, queda de raio, granizo, furacão e terremoto, estes danos precisam ser acima de 75% do valor do Bem.
                      <br /><br />Consulte informações detalhadas e limites nas <a href="https://suhaiseguradora.com/wp-content/uploads/Marco-atual.pdf" target="_blank">Condições Gerais</a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                      Assistência 24 horas
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                      A <strong>Suhai</strong> pensa na sua segurança e comodidade a todo momento, com isso você pode contar com o Serviço de Assistência 24 Horas para os eventos de <strong>chaveiro</strong>, <strong>reboque</strong>, <strong>transporte alternativo</strong>, <strong>troca de pneus</strong>, <strong>auxílio mecânico</strong> ou <strong>serviço de taxi</strong>.<br /><br />Nossa Central 24 horas estará disponível todos os dias e 24 horas. 
                    </p>
                    <p>
                      <a href="https://suhaiseguradora.com/wp-content/uploads/servico-de-assistencia-24-horas-2016-12.pdf" target="_blank">Condições gerais</a>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                      Emissão do Seguro e Início de Cobertura
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                      A emissão do seguro pela Suhai é feita após o Pagamento do Boleto da 1ª Parcela ou parcela única e a aprovação da Autovistoria ou Instalação Rastreador.
                    </p>
                    <p>
                      O início da cobertura do seguro se dará a partir da data/hora da efetivação da instalação do sistema de rastreamento, ou quando a contratação for sem sistema de rastreamento, o início da cobertura do seguro se dará a partir da data/hora da aprovação da Vistoria Prévia pela Seguradora e quando do pagamento do boleto da 1ª parcela ou parcela única e no prazo previsto.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </main>
      <footer class="footer">
        <div class="content">
          <p class="footer__copyright">Feito por <a href="https://www.togarantido.com.br" target="_blank"><img src={togarantidoLogo} /></a></p>
        </div>
      </footer>
    </div>
  );
}

export default RouboColisao;
